import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ITokenPayload } from 'src/app/auth/token-payload.interface';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
    loginForm = new FormGroup({
        username: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required]),
        otpCode: new FormControl('', [Validators.required]),
    });

    ipAddress: string = '';

    tokenPayload!: ITokenPayload;
    isLoginSubmitted: boolean = false;
    loginSuccessful: boolean = false;
    firstName: string = '';
    lastName: string = '';
    mobileNumber: string = '';
    maskedMobileNumber: string = '';

    otpToken: string = '';
    sendOtpSecondsToWait: number = 0;
    isSendOtpSubmitted: boolean = false;
    sendOtpSuccessful: boolean = false;

    isVerifyOtpSubmitted: boolean = false;
    verifyOtpSuccessful: boolean = false;

    constructor(private authService: AuthService, private router: Router) { }

    ngOnInit(): void {
        this.authService.getIPAddress().subscribe((response) => {
            if (response) {
                this.ipAddress = response.trim();
            }
        });
    }

    login(): void {
        const { username, password } = this.loginForm.getRawValue();
        this.authService.login(username, password).subscribe((data: { first_name: string | null, last_name: string | null, mobile_number: string | null } | null) => {
            this.isLoginSubmitted = true;
            if (data) {
                this.loginSuccessful    =  true;
                this.firstName          =  data.first_name || '';
                this.lastName           =  data.last_name || '';
                this.mobileNumber       =  data.mobile_number || '';
                this.maskedMobileNumber =  this.getMaskedMobileNumber(data.mobile_number || '', '000xxxxx00');
            }
        });
    }

    sendOtp(): void {
        this.resetIsVerifyOtpSubmitted();
        this.countdownToSendOtp();

        this.authService.sendOTP(this.mobileNumber).subscribe((token) => {
            this.isSendOtpSubmitted = true;
            if (token) {
                this.sendOtpSuccessful = true;
                this.otpToken = token;
            }
        });
    }

    verifyOTP(): void {
        const { otpCode, username, password } = this.loginForm.getRawValue();
        this.authService.verifyOTP(otpCode, this.otpToken, username!, password!, this.ipAddress).subscribe((token) => {
            this.isVerifyOtpSubmitted = true;
            if (token) {
                this.verifyOtpSuccessful = true;
                this.authService.setToken(token);
                document.body.appendChild(Object.assign(document.createElement('script'), { src: '/assets/scripts/uchat-popup.js', id: 'uchat-popup-version2' }));
                if (this.authService.redirectUrl) {
                    this.router.navigate([this.authService.redirectUrl]);
                } else {
                    this.router.navigate(['/order-balance']);
                }
            }
        });
    }

    refreshPage(): void {
        window.location.reload();
    }

    // utilities
    get f(): { [key: string]: AbstractControl } {
        return this.loginForm.controls;
    }

    private countdownToSendOtp(): void {
        this.sendOtpSecondsToWait = 60;
        const intervalId = setInterval(() => {
            this.sendOtpSecondsToWait--;
            if (this.sendOtpSecondsToWait === 0) {
                clearInterval(intervalId);
            }
        }, 1000);
    }

    private getMaskedMobileNumber(mobileNumber: string, mask: string): string {
        let maskedValue = '';
        for (let i = 0; i < mask.length; i++) {
            if (!mobileNumber[i]) {
                break;
            }

            if (mask[i] === 'x') {
                maskedValue += 'x';
            } else {
                maskedValue += mobileNumber[i];
            }
        }
        return maskedValue;
    }

    private resetIsVerifyOtpSubmitted(): void {
        this.isVerifyOtpSubmitted = false;
    }
}
